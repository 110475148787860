import React from "react";
import ReactDOM from "react-dom/client";
import "./custom.scss";
import reportWebVitals from "./reportWebVitals";
import Home from "Pages/Home";
import NotFoundPage from "Pages/NotFoundPage";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Qr from "Pages/Qr";

const root = ReactDOM.createRoot(document.getElementById("root"));
const routes = [
  {
    path: "",
    element: (
      <>
        <Outlet />
      </>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "employee/:id",
        element: <Home />,
      },
      {
        path: "download/qr/:id",
        element: <Qr/>,
      },
    ],
  },
];
const router = createBrowserRouter(routes);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
