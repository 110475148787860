import YellowCorner from "assets/yellow-corner.png";
import Logo from "assets/logo.png";
import BgLogo from "assets/bg-logo.png";
import PhotoExample from "assets/photo-example.png";
import RK from "assets/member/rk.png";
import KDI from "assets/member/kdi.png";
import PON from "assets/member/pondasi.png";
import IQI from "assets/member/iqi.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
const members = [RK, KDI, PON, IQI];

const Qr = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ data, isLoading }, settData] = useState({
    data: {},
    isLoading: true,
  });

  const fetchData = async () => {
    settData((v) => ({ ...v, isLoading: true }));
    const url = process.env.REACT_APP_API_URL + `/pub/employee/${id}/detail`;
    const response = await fetch(url, {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
    });

    if (!response.ok) {
      settData((v) => ({ ...v, isLoading: false }));
      navigate("/not-found");
    }

    const data = await response.json();
    settData((v) => ({ data: data?.data, isLoading: false }));
  };

  const saveToContact = () => {
  
    const vcard =
      "BEGIN:VCARD\nVERSION:4.0\nFN:" +
      data.name +
      "\nTEL;TYPE=work,voice:" +
      data.whatsapp +
      "\nEMAIL:" +
      data.email +
      "\nEND:VCARD";
    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    const newLink = document.createElement('a');
    newLink.download = data.name + ".vcf";
    newLink.textContent = data.name;
    newLink.href = url;
    newLink.click()
  };

  useEffect(() => {
    let mounted = true;

    if (mounted && id) {
      fetchData();
    }
  }, [id]);

  return (
    <div className="wrapper position-relative">
      <section className="logo-container align-content-center">
        <img src={Logo} alt="asa logo" className="img-fluid" />
      </section>
      <section className="group-qr container d-flex align-items-center justify-content-center">
        {/*<div className="d-flex flex-column bg-light rounded-5 px-4 py-3 text-dark justify-content-center">*/}
          <img
            src={data?.qr_code}
            alt="bg-logo"
            className="img-fluid position-absolute"
          />
        {/*</div>*/}
      </section>

      <section className="group-member-qr container d-flex align-items-center justify-content-center">
        <div className="d-flex flex-column bg-light rounded-5 px-4 py-2 text-dark justify-content-center">
          <h3 className="fw-normal text-center">Visit our member group</h3>
          <div className="member-list">
            {members.map((v, i) => (
              <img key={i} src={v} alt={i} className="h-100" />
            ))}{" "}
          </div>
        </div>
      </section>
      <img
        src={YellowCorner}
        className="position-absolute corner-frame"
        alt="corner-frame"
      />
    </div>
  );
};

export default Qr;
