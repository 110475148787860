import YellowCorner from "assets/yellow-corner.png";
import Logo from "assets/logo.png";
import BgLogo from "assets/bg-logo.png";
import PhotoExample from "assets/photo-example.png";
import RK from "assets/member/rk.png";
import KDI from "assets/member/kdi.png";
import PON from "assets/member/pondasi.png";
import IQI from "assets/member/iqi.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
const members = [RK, KDI, PON, IQI];

const Home = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ data, isLoading }, settData] = useState({
    data: {},
    isLoading: true,
  });

  const fetchData = async () => {
    settData((v) => ({ ...v, isLoading: true }));
    const url = process.env.REACT_APP_API_URL + `/pub/employee/${id}/detail`;
    const response = await fetch(url, {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
    });

    if (!response.ok) {
      settData((v) => ({ ...v, isLoading: false }));
      navigate("/not-found");
    }

    const data = await response.json();
    settData((v) => ({ data: data?.data, isLoading: false }));
  };

  const saveToContact = () => {
  
    const vcard =
      "BEGIN:VCARD\nVERSION:3.0\nFN:" +
      data.name +
      "\nTEL;TYPE=WORK,VOICE:" +
      data.whatsapp +
      "\nEMAIL:" +
      data.email +
      "\nEND:VCARD";
    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    const newLink = document.createElement('a');
    newLink.download = data.name + ".vcf";
    newLink.textContent = data.name;
    newLink.href = url;
    newLink.click()
  };

  useEffect(() => {
    let mounted = true;

    if (mounted && id) {
      fetchData();
    }
  }, [id]);

  return (
    <div className="wrapper position-relative">
      <section className="logo-container align-content-center">
        <img src={Logo} alt="asa logo" className="img-fluid" />
      </section>
      <section className="user-photo-section position-relative">
        <div className="position-absolute user-photo-container">
          <img
            src={BgLogo}
            alt="bg-logo"
            className="img-fluid position-absolute bg-logo"
          />
          <img
            src={data?.image}
            alt="bg-logo"
            className="img-fluid position-absolute user-photo"
          />
        </div>
      </section>
      <section className="contact-name-section container">
        <div className="profession-name">
          <h1 className="fw-semibold">{data.name}</h1>
          <h2 className="fs-6 fw-medium">Project & Product Development</h2>
        </div>
        <div className="contact mt-4">
          <button
            onClick={() => window.open("mailto:" + data.email)}
            className="btn btn-light rounded-pill d-flex align-items-center my-3"
          >
            <i className="bi bi-envelope-fill" />{" "}
            <span className="ms-2">{data?.email}</span>
          </button>
          <button
            onClick={() => window.open("https://wa.me/" + data.whatsapp)}
            className="btn btn-light rounded-pill d-flex align-items-center my-3"
          >
            <i className="bi bi-whatsapp" />{" "}
            <span className="ms-2">{data?.whatsapp}</span>
          </button>
          <button
            onClick={() =>
              window.open("https://id.linkedin.com/in/syamsul-arifin-2931821ba")
            }
            className="btn btn-light rounded-pill d-flex align-items-center my-3"
          >
            <i className="bi bi-linkedin" />{" "}
            <span className="ms-2">{data?.linked_in_title}</span>
          </button>
          <button onClick={saveToContact} className="btn btn-light rounded-pill d-flex align-items-center my-3">
            <i className="bi bi-phone" />{" "}
            <span className="ms-2">Save to Contact</span>
          </button>
        </div>
      </section>
      <address className="mb-0 head-office container">
        <div className="d-flex align-items-center mb-2">
          <div className="icon bg-light text-black rounded-circle me-2">
            <i className="bi-house-door-fill" />
          </div>
          <span className="fw-semibold">Head Office</span>
        </div>
        <p>
          The Manhattan Square Building, Mid Tower, 12th Floor Kav. 1S, Jl TB
          Simatupang, Cilandak Timur, Jakarta Selatan, 12560
        </p>
      </address>

      <address className="mb-0 marketing-office container">
        <div className="d-flex align-items-center mb-2">
          <div className="icon bg-light text-black rounded-circle me-2">
            <i className="bi-house-door-fill" />
          </div>
          <span className="fw-semibold">Marketing Office</span>
        </div>
        <p>
          Jalan Utama Kencana No.118, Jurang Manggu Timur, Pondok Aren, Kota
          Tangerang Selatan, Banten 15220
        </p>
      </address>

      <section className="group-member container d-flex align-items-center justify-content-center">
        <div className="d-flex flex-column bg-light rounded-5 px-4 py-3 text-dark justify-content-center">
          <h3 className="fw-normal text-center">Visit our member group</h3>
          <div className="member-list">
            {members.map((v, i) => (
              <img key={i} src={v} alt={i} className="h-100" />
            ))}{" "}
          </div>
        </div>
      </section>
      <img
        src={YellowCorner}
        className="position-absolute corner-frame"
        alt="corner-frame"
      />
    </div>
  );
};

export default Home;
